import React, {} from 'react';
import {ReactComponent as WalletImg} from "../../static/img/wallet.svg";
import AnimatedNumber from "../AnimatedNumber";
import {ReactComponent as BankImg} from "../../static/img/bank.svg";

function ItemEffectPointsPopup(props) {
    const item = props?.item;
    const group = props?.group;
    const onClose = props?.onClose || (() => {});

    let gClass;

    if (group?.id) {
        let mod = (group.id % 4 === 0) ? 4 : group.id % 4;
        gClass = `g${mod}`;
    }

    return (
        <div id="item-effect-points-popup" className="flex gap-1 centered-flex">
            <div className="flex flex-col flex-1 gap-1 centered-flex">
                <span className="item-title">{item?.name}</span>
                {function() {
                    let Comp = item?.img;
                    return (Comp) ? <i className="item-icon"><Comp height="100%"/></i> : <i></i>;
                }()}
                <p className="item-description">{item?.description}</p>
                <button id="backBtn" onClick={onClose}>Continue</button>
            </div>
            <div id='group-hud-holder' className="flex gap-1 flex-1 h-100 justify-around align-items-center">
                {group &&  <div id="currentGroupHUD">
                    <div className={"group " + gClass}>
                        <span className="group-name">{group.name}</span>
                        <div className="points-display">
                        <span className="group-round-points"><i><WalletImg height="100%"/></i>
                            <AnimatedNumber value={group.roundPoints}/></span>
                            <span className="group-game-points"><i><BankImg height="100%"/></i>
                            <AnimatedNumber value={group.gamePoints}/></span>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    );
}

export default ItemEffectPointsPopup;