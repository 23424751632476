import React, {} from 'react';
import useUUID from "../../useUUID";

function UpdateNotice(props) {

    const uuid = useUUID().uuid;

    const closeCallback = props?.closeCallback || (()=>{});
    const updates = props?.updates || [{
        key: uuid(),
        date: null,
        content: "Nothing new to see!"
    }];

    function getUpdateElements() {
        let elements = [];
        for (let i = 0; i < updates.length; i++) {
            let update = updates[i];
            elements.push(
                <li key={update.key}>{(update.date) ? (<b>{update.date + " - "}</b>) : ""}{update.content}</li>
            )
        }
        return elements;
    }

    return (
        <>
            <div id="update-notice" className="modal fade show" tabIndex="-1" aria-labelledby="updateModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header hstack gap-2">
                            <h1 className="modal-title fs-2 flex-1" id="updateModalLabel">Updates</h1>
                            <button type="button" className="btn-close" onClick={closeCallback} aria-label="Close">×</button>
                        </div>
                        <div className="modal-body">
                            <h3>Here is what's changed:</h3>
                            <ul>
                                {getUpdateElements()}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop" onClick={closeCallback}></div>
        </>
    );
}

export default UpdateNotice;