import {useRef, useState} from "react";
import PuzzleBoard from "../PuzzleBoard";
import selection from "../../static/audio/selection.mp3";
import itemReceived from '../../static/audio/item_received.mp3';
import ItemPopup from "../ItemPopup";
import {ITEM_HOOKS} from "../../Items";

const { floor, sqrt } = Math;
const { now } = Date;

function RouletteWheel(props) {

    const DBG_SHOW_ITEM_TILES = false;

    const puzzle = props?.puzzle;
    const boardState = props?.boardState;
    const backdrop = props?.backdrop;
    const continueCallback = props?.callback || startSpin;
    const group = props?.group;
    const items = props?.items;
    const itemLimits = props?.itemLimits;
    const itemToggles = props?.itemToggles;

    const audio = useRef();
    const spinBtn = useRef();
    const itemSquares = useRef({});
    const [result, setResult] = useState(null);
    const [showItem, setShowItem] = useState(null);
    const [itemNotificationResolver, setItemNotificationResolver] = useState(null);


    let currentBlock = 0;
    let startTime;
    let gClass;

    const DURATION = 2750;
    const MIN_UPDATES = 50;
    const MAX_UPDATES = 301;
    const MIN_ITEMS = 0;
    const MAX_ITEMS = 10;
    const ITEM_COUNT_WEIGHTS = [10,20,20,30,30,40,40,50,50,50,40];

    if (group?.id) {
        let mod = (group.id % 4 === 0) ? 4 : group.id % 4;
        gClass = `g${mod}`;
    }

    const showItemNotificationAndWait = (item) => {
        return new Promise((resolve) => {
            setItemNotificationResolver(() => resolve);
            setShowItem(item);
        });
    };

    const closeItemNotification = () => {
        setShowItem(null);
        if (itemNotificationResolver) {
            itemNotificationResolver();
            setItemNotificationResolver(null);
        }
    };

    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min);
    }

    // This function will generate a random int within a range.
    // Strength - to what degree the selection should be skewed (1.75 is fine for this app)
    // Min - lowest number in range (inclusive)
    // Max - highest number in range (inclusive)
    // toMax - boolean value. If true, favor higher numbers. If false, favor lower numbers
    const getRandomSkewedNum = (strength, min, max, toMax) => {
        let randPow = Math.pow(Math.random(), 1 / strength);
        if (!toMax) randPow = 1 - randPow;
        return Math.floor(randPow * (max - min + 1)) + min;
    };

    const getRandSkewedNumByWeights = (min, max, weights) => {
        let popArr = [];
        let weightCounter = 0;
        let res = 0;

        const shuffle = (array) => {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
        };

        if (weights?.length === (max-min+1)) {
            for (let i = min; i <= max; i++) {
                let weight = weights[weightCounter];
                for (let j = 0; j < weight; j++) {
                    popArr.push(i);
                }
                weightCounter++;
            }

            popArr = shuffle(popArr);
            res = popArr.pop();
        }

        return res;
    }


    let total_updates = getRandomInt(MIN_UPDATES, MAX_UPDATES);

    let elapsed = 0;
    let updates = 0;

    const easeOutCirc = (t, b, c, d) => {

        t /= d;
        t--;

        return c * sqrt(1 - t*t) + b;
    }

    function setItems() {
        let numItems = getRandSkewedNumByWeights(MIN_ITEMS, MAX_ITEMS, ITEM_COUNT_WEIGHTS);
        let squares = [];
        itemSquares.current = {};
        let els = document.querySelector('#roulette-wheel')?.children;
        for (const el of els) {
            if (el.className.startsWith("r")) {
                squares.push(el.className);
            }
        }

        for (let i = 0; i < numItems; i++) {
                let squareIdx = getRandomInt(0, squares.length);
                let item = determineSquareItem();
                if (!item) {
                    delete itemSquares.current[squares[squareIdx]];
                } else {
                    itemSquares.current[squares[squareIdx]] = item;
                    squares.splice(squareIdx, 1);
                }
        }

        if (DBG_SHOW_ITEM_TILES && itemSquares.current) {
            for (const sqIdx of Object.keys(itemSquares.current)) {
                document.querySelector(`#roulette-wheel > .${sqIdx}`)?.classList.add('dbg_item');
            }
        }

    }

    function determineSquareItem(limitedItems) {
        if (items) {

            let itemEntries = Object.entries(items).filter(([k,v]) => !v.unattainable && itemToggles[k]);

            if (limitedItems) {
                itemEntries = itemEntries.filter(([k,v]) => limitedItems.indexOf(k) === -1);
                if (itemEntries.length === 0) {
                    return null;
                }
            }

            let totalItemsWeight = itemEntries.reduce((acc, arr) => { return acc + arr[1].weight}, 0);
            let randPoint = getRandomInt(0, totalItemsWeight);
            let cursor = 0;

            for (let i = 0; i < itemEntries.length; i++) {
                let itemKey = itemEntries[i][0];
                let itemObj = itemEntries[i][1];
                cursor += itemObj.weight;
                if (cursor >= randPoint) {
                    if (isItemLimited(itemKey)) {
                        limitedItems = limitedItems || [];
                        limitedItems.push(itemKey);
                        return determineSquareItem(limitedItems);
                    }
                    return itemObj;
                }
            }
        }
    }

    function isItemLimited(itemKey) {

        let isLimited = false;

        if (itemLimits.hasOwnProperty(itemKey)) {
            let limits = itemLimits[itemKey]
            let gameLimited = limits.hasOwnProperty("game_limit") && limits["game_uses"] >= limits["game_limit"];
            let roundLimited = limits.hasOwnProperty("round_limit") && limits["round_uses"] >= limits["round_limit"];
            isLimited = gameLimited || roundLimited;
        }

        if (!itemToggles[itemKey]) {
            isLimited = true;
        }

        /*if (isLimited) {
            console.info(`ITEM "${itemKey}" LIMITED ALREADY REACHED!`);
        }*/

        return isLimited;
    }

    function startSpin(e) {

        if (!audio.current) {
            audio.current = window.UNMEIWA.sounds[selection];
        }
        spinBtn?.current.classList.add('locked');
        resetSpin();
        setItems();
        //console.log(itemSquares.current);

        startTime = now();
        changeBlock();
        playSound();
    }

    function resetSpin() {
        elapsed = 0;
        updates = 0;
        total_updates = getRandomInt(MIN_UPDATES, MAX_UPDATES);
        document.querySelector('#roulette-wheel > div.active')?.classList.remove('active');
    }

    function changeBlock() {
        elapsed = now() - startTime;

        elapsed = (elapsed > DURATION) ? DURATION : elapsed;

        const newUpdates = floor(easeOutCirc(elapsed, 0, total_updates, DURATION));

        if (newUpdates > updates) {
            updates = newUpdates;
            if (currentBlock !== 0) {
                document.querySelector(`.r${currentBlock}`).classList.remove('active');
            }

            currentBlock += 1;
            if (currentBlock > 24) {
                currentBlock = 1;
            }
            document.querySelector(`.r${currentBlock}`).classList.toggle('active');
        }

        if (updates < total_updates) {
            requestAnimationFrame(changeBlock);
        }
    }

    async function processResult() {
        let finalBlock = document.querySelector('#roulette-wheel > div.active');

        let blockID = (finalBlock.className.startsWith("r")) ? finalBlock.className.split(" ")[0] : null;
        let awardedItem;
        if (blockID && Object.keys(itemSquares.current).length > 0) {
            awardedItem = await checkForItem(blockID);
            // Debugging only
            // awardedItem = await checkForItem(Object.keys(itemSquares.current)[0]);
        }
        spinBtn?.current.classList.remove("locked");
        setResult({blockValue: finalBlock.textContent, item: awardedItem});
    }

    async function checkForItem(squareID) {
        let item = itemSquares.current && itemSquares.current[squareID];

        if (item) {

            // Only award item if the group has an empty item slot
            let hasSpace = group.items?.length < 3;

            // Only award item if the group doesn't already have it
            let doesNotHave = group.items?.findIndex((x) => x.name === item.name) === -1;

            if (item.hook === ITEM_HOOKS.ACQUISITION) {
                await item.activate(item, group);
            } else if (hasSpace && doesNotHave) {
                audio.current = window.UNMEIWA.sounds[itemReceived];
                audio.current.onended = null;
                audio.current.play().then(()=> {audio.current = null});
                await showItemNotificationAndWait(item);
                return item;
            }

        }
    }

    function playSound()
    {
        audio.current.onended = processResult;
        audio.current.play().catch((e)=>{
            console.log(e);
        });
    }

    function followCallback() {
        if (continueCallback) {
            continueCallback(result);
        }
    }

    return (

        <div id="roulette-wheel" className={gClass}>
            <div className="r1">500</div>
            <div className="r2">900</div>
            <div className="r3">700</div>
            <div className="r4">300</div>
            <div className="r5">800</div>
            <div className="bankrupt r6">BANKRUPT</div>
            <div className="r7">400</div>
            <div className="r8">500</div>
            <div className="r9">600</div>
            <div className="r10">350</div>
            <div className="r11">500</div>
            <div className="r12">900</div>
            <div className="bankrupt r13">BANKRUPT</div>
            <div className="r14">650</div>
            <div className="free-vowel r15">FREE VOWEL</div>
            <div className="r16">700</div>
            <div className="lose-a-turn r17">LOSE A TURN</div>
            <div className="r18">800</div>
            <div className="r19">500</div>
            <div className="r20">450</div>
            <div className="r21">500</div>
            <div className="r22">300</div>
            <div className="bankrupt r23">BANKRUPT</div>
            <div className="r24">2000</div>
            <div className="puzzle">
                {showItem && <ItemPopup item={showItem} acquiring={true} onClose={closeItemNotification}/>}
                {!showItem && <PuzzleBoard backdrop={backdrop} boardState={boardState} puzzle={puzzle}/>}
                <button id="spinBtn" className={gClass} ref={spinBtn} onClick={(result) ? followCallback : startSpin}>{(result) ? result.blockValue : "SPIN"}</button>
            </div>
        </div>
    );
}

export default RouletteWheel;