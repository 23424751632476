import React, {useEffect, useRef} from 'react';

function AnimatedNumber(props) {

    const currentVal = props?.value || 0;
    const oldVal = useRef(props?.value || 0);
    const spanEl = useRef();

    useEffect(()=>{
        if (currentVal !== oldVal.current && spanEl.current) {
            // console.info(`Animating: ${oldVal.current} to ${currentVal}`);
            animateValue(spanEl.current, oldVal.current, currentVal, 1000, ()=>{
                oldVal.current = currentVal;
            });
        }
    }, [currentVal]);

    function animateValue(obj, start, end, duration, callback) {
        let startTimestamp = null;
        spanEl.current?.classList.add((start > end) ? "dec" : "inc");
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            obj.innerHTML = Math.floor(progress * (end - start) + start);
            if (progress < 1) {
                window.requestAnimationFrame(step);
            } else {
                spanEl.current?.classList.remove("inc", "dec");
                if (callback) {
                    callback();
                }
            }
        };
        window.requestAnimationFrame(step);
    }


    return (
        <span ref={spanEl} className="animated-number">{oldVal.current || 0}</span>
    );
}

export default AnimatedNumber;