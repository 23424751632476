import { motion } from 'framer-motion';
import first from "./img/1.svg";
import second from "./img/2.svg";
import third from "./img/3.svg";
import {ReactComponent as TieBreakerBeltImg} from '../../static/img/items/tiebreakerbelt.svg';

export default function WinnerCard(props) {

    const winners = props?.winners;
    const winner = props?.winner;
    const index = props?.index;
    const isTied = winner?.name.indexOf("Tie (") !== -1;

    const positions = {
        0: '1st',
        1: '2nd',
        2: '3rd'
    };

    const placeImgs = [
        first,
        second,
        third
    ];

    return (
        <motion.div
            custom={index}
            initial="hidden"
            animate="visible"
            variants={{
                visible: () => ({
                    opacity: 1,
                    y: 0,
                    transition: {
                        delay: (winner.place === 2) ? 1 : 1 + (winners.length - winner.place),
                        duration: 2,
                        ease: 'backInOut'
                    }
                }),
                hidden: { opacity: 0, y: -100 }
            }}
            key={winner.id}
            className=""
        >
            <div
                className="winner-card"
            >
                <img
                    src={placeImgs[winner.place]}
                    alt=""
                    className="winner-card-img"
                />

                <div className="winner-card-info">
                    <p className="text-gray-900 leading-none font-semibold">
                        {isTied && winner?.name && "Tied"}
                        {isTied && winner?.name && <span className="tied-text">{winner.name.substring(5, winner.name.length - 1)}</span>}
                        {(!isTied && winner.name) || (!isTied  && 'No name')}
                    </p>
                    <p className="text-gray-600 flex centered-flex">{winner?.hasTBB && <i className="winner-card-tbb"><TieBreakerBeltImg height="100%"/></i>}{winner.score}</p>

                </div>
            </div>
        </motion.div>
    )
}
