import React, {} from 'react';

function ItemPopup(props) {

    const item = props?.item;
    const acquiring = props?.acquiring === true;
    const onClose = props?.onClose || (() => {});

    return (
        <div id="item-popup" className="flex flex-col gap-1 centered-flex">
            <span className="item-title">{item?.name}</span>
            {function() {
                let Comp = item?.img;
                return (Comp) ? <i className="item-icon"><Comp height="100%"/></i> : <i></i>;
            }()}
            <p className="item-description">{item?.description}</p>
            <button id="closeItemBtn" onClick={onClose}>Continue</button>
        </div>
    );
}

export default ItemPopup;