import './App.css';
import {useRef, useState, useEffect, useMemo} from "react";
import PuzzleBoard from "./components/PuzzleBoard";
import GroupDisplay from "./components/GroupDisplay";
import RouletteWheel from "./components/RouletteWheel";
import LetterSelector from "./components/LetterSelector";
import Preloader from "./components/Preloader";


import playImg from './static/img/play.svg';
import bankImg from './static/img/bank.svg';
import walletImg from './static/img/wallet.svg';
import noWinnerImg from './static/img/noscore.svg';

import {ReactComponent as HelpImg} from "./static/img/help.svg";
import {ReactComponent as StopImg} from "./static/img/stop.svg";
import {ReactComponent as SettingsImg} from "./static/img/settings.svg";
import {ReactComponent as SkipImg} from "./static/img/skip.svg";

import {ITEM_HOOKS, ITEMS, ITEM_LIMITS_BASE, ITEM_TOGGLES_BASE, getItemKeyByName} from "./Items";

import bankruptAud from './static/audio/bankrupt.mp3';
import correctAud from './static/audio/correct.mp3';
import incorrectAud from './static/audio/incorrect.mp3';
import newPuzzleAud from './static/audio/new_puzzle.mp3';
import selectionAud from './static/audio/selection.mp3';
import itemReceivedAud from './static/audio/item_received.mp3';
import solvedAud from './static/audio/solved.mp3';
import cashAud from './static/audio/cash.mp3';
import loseATurnAud from './static/audio/loseaturn.mp3';
import bankAud from './static/audio/bank.mp3';
import cheerAud from './static/audio/cheering.mp3';
import payingAud from './static/audio/pay.mp3';
import pointLeechAud from './static/audio/point_leech.mp3';
import poisonedAud from './static/audio/poisoned.mp3';
import ringOfPowerAud from './static/audio/ring_of_power.mp3';
import bombAud from './static/audio/bomb.mp3';
import treasureAud from './static/audio/treasure.mp3';
import podiumAud from './static/audio/podium.mp3';

import TurnOptionSelector from "./components/TurnOptionSelector";
import SolveThePuzzle from "./components/SolveThePuzzle";
import GameSetup from "./components/GameSetup";
import useDebounce from "./useDebounce";
import WinnerPodium from "./components/WinnerPodium";
import HelpScreen from "./components/HelpScreen";
import UpdateNotice from "./components/UpdateNotice";
import useUUID from "./useUUID";
import ItemPopup from "./components/ItemPopup";
import ChooseGroupPopup from "./components/ChooseGroupPopup";
import ItemEffectPointsPopup from "./components/ItemEffectPointsPopup";


// TODO: Fix add puzzle button on iPad
// TODO: Maybe try to fix up the visuals???

// FIXME: SVG background rerendering each time the roulette or letter selector is shown. Can cause small visual glitch as it reloads.

// FUTURE: Add ability to hold inc/dec buttons for number entry

function App() {

    const DEBUG = false;

    const uuid = useUUID().uuid;

    function importAll(r) {
        let images = {};
        r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
        return images
    }

    const backdrops = importAll(require.context('./components/PuzzleBoard/img/', false, /\.(png|jpe?g|svg)$/));
    const solids = [
        "var(--umw-col-green)",
        "var(--umw-col-red)",
        "var(--umw-col-orange)",
        "var(--umw-col-yellow)",
        "var(--umw-col-blue)",
        "var(--umw-col-purple)"
    ];

    const SOLVE_POINTS = 3000;
    const DEFAULT_VOWEL_COST = 500;
    const LS_UPDATE_NOTICE_KEY = "LAST_UPDATE_SEEN";

    const UPDATE_NOTICES = useMemo(()=> [
        {key: uuid(), date: "01/10/2025", content: "Implemented acquiring, holding, and using items."},
        {key: uuid(), date: "08/16/2024", content: "Once again return to this project after a long hiatus. Customizable response timer has now been added for selecting actions/vowels and for solving the puzzle. This will prevent students from holding up the game for others. If the timer expires, their turn is over."},
        {key: uuid(), date: "08/26/2023", content: "Working on this again after some time off. The winner's podium can now handle ties correctly."},
        {key: uuid(), date: "07/24/2023", content: "If all vowels have already been purchased, landing on 'Free Vowel' will award students with points equal " +
                "to the game's vowel cost. Pressing the end game button will now transfer any existing round points to the players' banks, " +
                "show the answer to the current puzzle, and prompt you with a 'Finish' button before showing the winner's podium."},
        {key: uuid(), date: "07/19/2023", content: "Changed the default vowel cost to 500. Added this update notification screen."}
    ], []);

    // TODO: Consider using importAll for audio too.
    const AUDIO = [payingAud, bankruptAud, correctAud, incorrectAud, newPuzzleAud, selectionAud, solvedAud, cashAud,
        loseATurnAud, bankAud, cheerAud, pointLeechAud, poisonedAud, ringOfPowerAud, itemReceivedAud, bombAud, treasureAud,
        podiumAud];

    useEffect(() => {
        hasNewUpdates();
    }, []);


    const [puzzle, setPuzzle] = useState(null);

    const [showLetterSelector, setShowLetterSelector] = useState(false);
    const [showRouletteWheel, setShowRouletteWheel] = useState(false);
    const [showTurnOptions, setShowTurnOptions] = useState(false);
    const [showSolveThePuzzle, setShowSolveThePuzzle] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [showUpdates, setShowUpdates] = useState(false);
    const [showItem, setShowItem] = useState(null);
    const [showItemAcquisitionEffect, setShowItemAcquisitionEffect] = useState(null);
    const [showGroupChooser, setShowGroupChooser] = useState(null);
    const [itemNotificationResolver, setItemNotificationResolver] = useState(null);
    const [puzzleSolved, setPuzzleSolved] = useState(false);
    const [showPodium, setShowPodium] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [setUp, setSetUp] = useState(false);
    const [backdrop, setBackdrop] = useState();

    const [boardState, setBoardState] = useState(initBoardState());
    const [groups, setGroups] = useState([]);

    const playingGroupID = useRef(null);
    const buyingAVowel = useRef(false);
    const freeVowel = useRef(false);
    const letterValue = useRef(0);
    const guessesToClear = useRef(0);
    const vowelCost = useRef(DEFAULT_VOWEL_COST);
    const vowelsPurchased = useRef([]);
    const consonantsUsed = useRef([]);
    const puzzleSet = useRef([]);
    const skipped = useRef(false);
    const backdropRawName = useRef(null);
    const hasUpdates = useRef(false);
    const turnTimerDuration = useRef(9999);
    const isNotificationShowing = useRef(false);

    const audioContainer = useRef(null);

    ITEMS.POINT_LEECH.activate = leechPoints;
    ITEMS.POISON.clickHandler = usePoison;
    ITEMS.POISON_STATUS.activate = isPoisoned;
    ITEMS.RING_OF_POWER.activate = ringOfPower;
    ITEMS.BOMB.activate = bomb;
    ITEMS.TREASURE.activate = treasure;

    const itemLimits = useRef(ITEM_LIMITS_BASE);
    const itemToggles = useRef(ITEM_TOGGLES_BASE);

    useEffect(()=> {
        if (loaded && !setUp && DEBUG) {
            autoSettings();
        }
    }, [loaded, setUp]);

    useEffect(()=> {

        if (loaded && setUp) {
            let cleanString = puzzle.puzzle
                .replace(/[.,/#!$%^&*;:{}=\-_`~()]/g,"")
                .replace(/\s{2,}/g," ");
            getBoard(cleanString);
            if (audioContainer.current) {
                audioContainer.current.play().catch((e)=>{alert(`Audio error: ${e}`)});
            }
        }

    }, [puzzle, puzzle?.puzzle, loaded, setUp]);

    useEffect(()=> {
        let resetGroups = (groups?.length > 0);
        for (const group of groups) {
            if (!group.completedTurn) {
                resetGroups = false
                break;
            }
        }

        if (resetGroups) {
            let groupsUpdate = groups.slice();
            for (const group of groupsUpdate) {
                group.completedTurn = false;
            }
            setGroups(groupsUpdate);
        }

    }, [groups]);

    const showItemNotificationAndWait = (item) => {
        isNotificationShowing.current = true;
        return new Promise((resolve) => {
            setItemNotificationResolver(() => {
                return (() => {
                    isNotificationShowing.current = false;
                    resolve();
                });
            });
            if (item.hook === ITEM_HOOKS.ACQUISITION) {
                setShowItemAcquisitionEffect(item);
                itemLimits.current[getItemKeyByName(item.name)].round_uses++;
            } else {
                setShowItem(item);
            }
        });
    };

    const showGroupChooserAndWait = (item) => {
        isNotificationShowing.current = true;
        return new Promise((resolve) => {
            setItemNotificationResolver(() => {
                return ((groupID) => {
                    isNotificationShowing.current = false;
                    resolve(groupID);
                });
            });
            setShowGroupChooser(item);
        });
    };

    function initBoardState() {
        let initState = [];
        for (let i = 0; i < 60; i++) {
            initState.push({used: false, value: "", guessed: false, show: false});
        }
        return initState;
    }

    function backdropSelected(name) {
        backdropRawName.current = name;
        if (name && backdrops.hasOwnProperty(name)) {
            setBackdrop(backdrops[name]);
        } else if (name && solids.includes(name)) {
            setBackdrop(name);
        }
    }

    function getBoard(puzzleString) {
        let words = puzzleString.split(" ");
        let currentRowIdx = 1;
        let currentArrIdx = 0;
        let newBoard = boardState.slice();

        function addWord(word) {
            for (let i = 0; i < word.length; i++) {
                newBoard[currentArrIdx] = {used: true, value: word[i].toUpperCase(), guessed: false};
                currentArrIdx++;
            }
        }

        for (const word of words) {
            const wordLen = word.length;
            if (currentArrIdx + wordLen <= 15 * currentRowIdx) {
                addWord(word);
            } else {
                currentArrIdx = currentRowIdx * 15;
                currentRowIdx += 1
                if (currentArrIdx + wordLen < 60) {
                    addWord(word);
                } else {
                    // The puzzle is too long, so truncate the rest.
                    break;
                }
            }

            newBoard[currentArrIdx] = {used: false, value: "", guessed: false};
            currentArrIdx += 1;
        }
        setBoardState(newBoard);
    }

    async function afterRoulette (rouletteResult) {

        let points = rouletteResult.blockValue;
        let awardedItem = rouletteResult.item;
        let isNumber = !isNaN(Number(points));

        if (awardedItem) {
           addItem(playingGroupID.current, awardedItem);
        }

        if (isNumber) {
            letterValue.current = Number(points);
            setShowLetterSelector(true);
        } else {
            switch (points) {
                case "BANKRUPT":
                    window.UNMEIWA.sounds[bankruptAud].play();
                    setShowRouletteWheel(false);
                    let updateObj = {completedTurn: true};
                    if (getGroupByID(playingGroupID.current).roundPoints > 0) {
                        updateObj.roundPoints = 0;
                    }
                    setTimeout(async ()=>{
                        await updateGroup(playingGroupID.current, updateObj);
                        playingGroupID.current = null;
                    }, 1000);
                    break;

                case "FREE VOWEL":
                    if (vowelsPurchased?.current.length >= 5) {
                        // All vowels have been purchased, so give the team some points.
                        window.UNMEIWA.sounds[cashAud].play();
                        setTimeout(()=>{
                            updateGroup(playingGroupID.current, {
                                    completedTurn: false,
                                    roundPoints: getGroupByID(playingGroupID.current).roundPoints + vowelCost.current
                                }
                            );
                            setTimeout(setShowTurnOptions.bind(this, true), 1800);
                        }, 500);
                    } else {
                        buyingAVowel.current = true;
                        freeVowel.current = true;
                        setShowLetterSelector(true);
                    }
                    break;
                case "LOSE A TURN":
                    window.UNMEIWA.sounds[loseATurnAud].play();
                    setShowRouletteWheel(false);
                    setTimeout(()=> {
                        updateGroup(playingGroupID.current, {completedTurn: true});
                        playingGroupID.current = null;
                    }, 0);
                    break;
                default:
                    break;
            }
        }

        setShowRouletteWheel(false);
    }

    function afterLetterSelection (letter) {

        // Check if letter in puzzle
        // Set puzzle board square states (pass as prop)
        // Multiply points by number of squares correct
        // Add points to group current points account

        let incorrect = function () {
            let endTurn = ()=> {
                setShowLetterSelector(false);
                setTimeout(()=> {
                    updateGroup(playingGroupID.current, {completedTurn: true});
                    playingGroupID.current = null;
                }, 0);
                buyingAVowel.current = false;
            };

            let aud = window.UNMEIWA.sounds[incorrectAud];
            aud.onended = endTurn;
            aud.play().catch(endTurn);
        }

        if (!letter) {
            incorrect();
            return;
        }

        freeVowel.current = false;
        const isVowel = "AEIOU".indexOf(letter) !== -1;
        if (isVowel && !vowelsPurchased?.current.includes(letter)) {
            vowelsPurchased.current.push(letter);
        }

        if (!isVowel) {
            consonantsUsed.current.push(letter);
        }

        if (puzzle.puzzle.indexOf(letter) === -1) {
            incorrect();
        } else {
            setShowLetterSelector(false);
            window.UNMEIWA.sounds[correctAud].play();
            let boardStateUpdate = boardState.slice();
            for (let block of boardStateUpdate) {
                if (block.value === letter) {
                    block.guessed = true;
                    guessesToClear.current += 1;
                }
            }
            setBoardState(boardStateUpdate);
        }

    }

    async function handleGuessedClick(blockIdx, e) {
        let boardStateUpdate = boardState.slice();
        boardStateUpdate[blockIdx].guessed = false;

        if (!buyingAVowel.current) {
            let cashSound = window.UNMEIWA.sounds[cashAud];
            if (cashSound.currentTime !== 0 && !cashSound.ended) {
                cashSound.currentTime = 0;
            }  else {
                cashSound.play();
            }
            updateGroup(playingGroupID.current, {
                roundPoints: getGroupByID(playingGroupID.current).roundPoints + letterValue.current
            });
        }

        boardStateUpdate[blockIdx].show = true;
        setBoardState(boardStateUpdate);

        guessesToClear.current -= 1;

        if (guessesToClear.current === 0) {

            let emptySquare = boardStateUpdate.find((x)=> x.used && !x.show);
            if (!emptySquare) {
                window.UNMEIWA.sounds[solvedAud].play();
                setShowSolveThePuzzle(true);
            } else {
                buyingAVowel.current = false;

                if (isNotificationShowing.current) {
                    const ir = itemNotificationResolver;
                    setItemNotificationResolver(() => {
                        return () => {
                            if (ir) { ir(); }
                            isNotificationShowing.current = false;
                            setTimeout(setShowTurnOptions.bind(this, true), 500);
                        }
                    });
                } else {
                    setTimeout(setShowTurnOptions.bind(this, true), 1800);
                }
            }
        }
    }

    function getGroupByID(groupID) {
        let filtered = groups.filter((v)=> v.id === groupID);
        return (filtered.length > 0) ? filtered[0] : null;
    }

    async function updateGroup(groupID, props) {

        let groupsUpdate = groups.slice();
        let updatingGroup = getGroupByID(groupID);
        let pointsDelta;

        if (props?.hasOwnProperty("roundPoints") && !props?.hasOwnProperty("gamePoints")) {
            pointsDelta = props.roundPoints - updatingGroup.roundPoints;
        }

        for (let i = 0; i < groupsUpdate.length; i++) {
            let group = groupsUpdate[i];
            if (group.id === groupID) {
                groupsUpdate[i] = {...group, ...props};
                break;
            }
        }

        if (props?.hasOwnProperty("roundPoints") && !props?.hasOwnProperty("gamePoints")) {
            props.pointsDelta = pointsDelta;
            if (props.roundPoints > updatingGroup.roundPoints) {
                await processItemHook(ITEM_HOOKS.GAIN_POINTS, groupsUpdate, props);
            } else if (props.roundPoints < updatingGroup.roundPoints) {
                await processItemHook(ITEM_HOOKS.LOSE_POINTS, groupsUpdate, props);
            }
        }

        if (props?.completedTurn) {
            await processItemHook(ITEM_HOOKS.END_OF_TURN, groupsUpdate);
        }

        setGroups(groupsUpdate);
    }

    function handleGroupClick(groupID) {
        if (!playingGroupID.current && !puzzleSolved) {
            let group = getGroupByID(groupID);
            if (group && !group.completedTurn) {
                playingGroupID.current = group.id;
                setShowTurnOptions(true);
            }
        }
    }

    async function doTurnOption(option) {

       switch (option) {
           // Spin
           case 0:
               setShowTurnOptions(false);
               setShowRouletteWheel(true);
               break;
           // Buy a vowel
           case 1:
               buyingAVowel.current = true;
               updateGroup(playingGroupID.current,
                   {roundPoints: getGroupByID(playingGroupID.current).roundPoints - vowelCost.current});
               window.UNMEIWA.sounds[payingAud].play();
               setTimeout(()=> {
                   setShowTurnOptions(false);
                   setShowLetterSelector(true);
               }, 2000);
               break;
           // Solve the puzzle
           case 2:
               setShowTurnOptions(false);
               setShowSolveThePuzzle(true);
               break;
           // Pass turn
           case 3:
               setShowTurnOptions(false);
               updateGroup(playingGroupID.current, {completedTurn: true});
               playingGroupID.current = null;
               break;
           default:
               setShowTurnOptions(false);
               playingGroupID.current = null;
               break;
       }

    }

    function addItem(groupID, item) {
        let group = getGroupByID(groupID);
        if (group) {
            if (!group.items) {
                group.items = [];
            }

            group.items.push({...item});

            let limitObj = itemLimits.current[getItemKeyByName(item.name)];
            limitObj.game_uses = (limitObj.game_uses && limitObj.game_uses !== 0) ? limitObj.game_uses + 1 : 1;
            limitObj.round_uses = (limitObj.round_uses && limitObj.round_uses !== 0) ? limitObj.round_uses + 1 : 1;

            // console.info(itemLimits);

            updateGroup(groupID, {items: group.items});
        }
    }

    async function processItemHook(hookType, groupsUpdate, props) {

        switch (hookType) {

            case ITEM_HOOKS.START_OF_ROUND:
            case ITEM_HOOKS.START_OF_TURN:
                break;

            case ITEM_HOOKS.END_OF_TURN:
                await resolveEndTurnItems(groupsUpdate);
                break;

            case ITEM_HOOKS.END_OF_ROUND:
                await resolveEndRoundItems(groupsUpdate);
                break;

            case ITEM_HOOKS.GAIN_POINTS:
                await resolveGainPointsItems(groupsUpdate, props);
                break;

            case ITEM_HOOKS.ACQUISITION:
                await resolveAcquisitionItems(groupsUpdate, props);
                break;

            case ITEM_HOOKS.END_OF_GAME:
            case ITEM_HOOKS.LOSE_POINTS:
            case ITEM_HOOKS.USE_ITEM:
            case ITEM_HOOKS.TARGETED_BY_ITEM:
            default:
                break;
        }

        checkAndResetItems(groupsUpdate);

    }

    function clearExpiredRoundItems(groupsUpdate) {

        groupsUpdate.forEach((x)=> {
            x.items = x.items.filter(item => !item.round_expire)
        });

        setGroups(groupsUpdate);
    }

    function checkAndResetItems(groupsUpdate) {

        // Check uses/useLimit
        // Clear expired turn items
        groupsUpdate.forEach((x)=> {
            x.items = x.items.filter(item => item.hook !== ITEM_HOOKS.END_OF_TURN || (item.hook === ITEM_HOOKS.END_OF_TURN && item.uses < item.useLimit))
        });

        // Reset resolved
        groupsUpdate.forEach((x)=> {
            x.items = x.items.map((item) => {
                item.resolved = false;
                if (x.completedTurn && item.hasOwnProperty("notifications")) {
                    item.notifications = 0;
                }
                return item;
            });
        });

    }

    async function resolveEndTurnItems(groupsUpdate) {
        // TODO: DRY
        let hasPendingItems = true;

        while (hasPendingItems) {
            hasPendingItems = false;

            for (let i = 0; i < groupsUpdate.length; i++) {
                const group = groupsUpdate[i];
                const endOfTurnActivations = group.items.filter(item => item.hook === ITEM_HOOKS.END_OF_TURN && !item.resolved);

                if (endOfTurnActivations.length > 0) {
                    hasPendingItems = true;
                    const nextItem = endOfTurnActivations.shift();
                    await nextItem.activate(nextItem, group, groupsUpdate);
                    setGroups([...groupsUpdate]);
                }
            }
        }
    }

    async function resolveEndRoundItems(groupsUpdate) {

        let hasPendingItems = true;

        while (hasPendingItems) {
            hasPendingItems = false;

            for (let i = 0; i < groupsUpdate.length; i++) {
                const group = groupsUpdate[i];
                const endOfRoundActivations = group.items.filter(item => item.hook === ITEM_HOOKS.END_OF_ROUND && !item.resolved);

                if (endOfRoundActivations.length > 0) {
                    hasPendingItems = true;
                    const nextItem = endOfRoundActivations.shift();
                    await nextItem.activate(nextItem, group, groupsUpdate);
                    setGroups([...groupsUpdate]);
                }
            }
        }

        clearExpiredRoundItems(groupsUpdate);
    }

    async function resolveAcquisitionItems(groupsUpdate, props) {

    }

    async function resolveGainPointsItems(groupsUpdate, props) {
        let hasPendingItems = true;

        while (hasPendingItems) {
            hasPendingItems = false;

            for (let i = 0; i < groupsUpdate.length; i++) {
                const group = groupsUpdate[i];
                const gainPointsActivations = group.items.filter(item => item.hook === ITEM_HOOKS.GAIN_POINTS && !item.resolved);

                if (gainPointsActivations.length > 0) {
                    hasPendingItems = true;
                    const nextItem = gainPointsActivations.shift();
                    await nextItem.activate(nextItem, group, groupsUpdate, props);
                    setGroups([...groupsUpdate]);
                }
            }
        }
    }

    function closeItemNotification() {
        setShowItem(null);
        setShowItemAcquisitionEffect(null);
        if (itemNotificationResolver) {
            itemNotificationResolver();
            setItemNotificationResolver(null);
        }
    }

    function closeGroupChooser(groupID) {
        setShowGroupChooser(null);
        if (itemNotificationResolver) {
            groupID = (typeof groupID === "number") ? groupID : null;
            itemNotificationResolver(groupID);
            setItemNotificationResolver(null);
        }
    }

    function correctSolve() {
        setShowSolveThePuzzle(false);
        puzzleSet.current.find((x)=> x.id === puzzle.id).finished = true;
        setPuzzleSolved(true);
        setTimeout(()=> {
            window.UNMEIWA.sounds[cashAud].play();
            updateGroup(playingGroupID.current, {
                roundPoints: getGroupByID(playingGroupID.current).roundPoints + SOLVE_POINTS
            });
        }, 0);
    }

    function incorrectSolve() {

        async function finish() {
            setShowSolveThePuzzle(false);
            setTimeout(()=>{
                updateGroup(playingGroupID.current, {completedTurn: true});
                playingGroupID.current = null;
            }, 0);
        }

        let aud = window.UNMEIWA.sounds[incorrectAud]
        aud.onended = finish;
        aud.play().catch(finish);
    }

    /* Group Object Def:
     *  roundPoints : int
     *  gamePoints : int
     *  completedTurn : boolean
     *  items : object
     *  isPoisoned: boolean
     */

    async function finishPuzzle() {

        let groupsUpdate = groups.slice();

        function finish() {
            setTimeout(nextPuzzle, 1800);
        }

        if (!skipped.current) {

            let hasRoundPoints = groupsUpdate.find((x)=> x.roundPoints > 0);

            await processItemHook(ITEM_HOOKS.END_OF_ROUND, groupsUpdate);

            if (hasRoundPoints) {

                groupsUpdate.forEach((x) => {
                    x.gamePoints = x.gamePoints + x.roundPoints;
                    x.roundPoints = 0;
                    x.completedTurn = false;
                });

                let aud = window.UNMEIWA.sounds[bankAud];
                aud.onended = finish;
                setGroups([...groupsUpdate]);
                aud.play().catch(finish);

            } else {
                groupsUpdate.forEach((x)=> {
                    x.completedTurn = false;
                });
                setGroups(groupsUpdate);
                finish();
            }

        } else {
            skipped.current = false;
            let groupsUpdate = groups.slice();
            groupsUpdate.forEach((x)=> {
                x.roundPoints = 0;
                x.completedTurn = false;
            });
            setGroups(groupsUpdate);
            finish();
        }

    }

    function reset() {
        playingGroupID.current = null;
        buyingAVowel.current = false;
        freeVowel.current = false;
        letterValue.current = 0;
        guessesToClear.current = 0;
        vowelsPurchased.current = [];
        consonantsUsed.current = [];

        // Reset round-based item limits.
        Object.values(itemLimits.current).forEach((v)=> {
            if (v.hasOwnProperty("round_uses")) {
                v.round_uses = 0;
            }
        });

        setPuzzleSolved(false);
    }

    function hasNextPuzzle() {
        return puzzleSet.current?.find((x) => !x.finished);
    }

    function nextPuzzle() {
        let next = hasNextPuzzle();
        if (next) {
            reset();
            setBoardState(initBoardState());
            setPuzzle(next);
        } else {
            gameOver();
        }
    }

    function finalizeSetup(settingsObj) {

        if (!settingsObj || Object.keys(settingsObj).length === 0) {
            alert("There has been a critical error. The page will now reload.");
            window.location.reload();
            return;
        }

        if (settingsObj.puzzleSet) {
            puzzleSet.current = settingsObj.puzzleSet;
            let groupsUpdate = [];

            for (let i = 0; i < settingsObj.playerCount; i++) {
                groupsUpdate.push(
                    {id: i+1, name:`Group ${i+1}`, roundPoints: 0, gamePoints: 0, completedTurn: false, items: []},
                )
            }



/*            groupsUpdate[0].items.push({...ITEMS.RING_OF_POWER});
            groupsUpdate[0].items.push({...ITEMS.POINT_LEECH});
            groupsUpdate[0].items.push({...ITEMS.TIE_BREAKER_BELT});
            groupsUpdate[1].items.push({...ITEMS.POINT_LEECH});
            groupsUpdate[2].items.push({...ITEMS.POISON});
            groupsUpdate[2].items.push({...ITEMS.POISON_STATUS});
            groupsUpdate[2].roundPoints = 2000*/

            audioContainer.current.autoplay = false;
            audioContainer.current.src = newPuzzleAud;
            audioContainer.current.load();

            vowelCost.current = settingsObj.vowelCost;
            turnTimerDuration.current = (settingsObj.useTurnTimer) ? settingsObj.turnTimerDuration : 0;
            itemToggles.current = {...settingsObj.itemToggles};
            setGroups(groupsUpdate);
            nextPuzzle();
            setSetUp(true);
        }
    }

    function updateSettings(settingsObj) {

        if (settingsObj.restartRound) {
            let currentPuzzleID = puzzle.id;
            let matchingPuzzle = settingsObj.puzzleSet.find((x)=> x.id === currentPuzzleID);
            if (matchingPuzzle) {
                puzzleSet.current = settingsObj.puzzleSet;
                reset();
                setPuzzle(matchingPuzzle);
            }
        } else if (settingsObj.currentPuzzleDeleted) {
            if (settingsObj.nextPuzzleID) {
                let matchingPuzzle = settingsObj.puzzleSet.find((x)=> x.id === settingsObj.nextPuzzleID);
                if (!matchingPuzzle) {
                    let nextPuzzle = settingsObj.puzzleSet.findIndex((x)=> !x.finished);
                    matchingPuzzle = (nextPuzzle !== -1) ? settingsObj.puzzleSet[nextPuzzle] : null;
                }

                if (!matchingPuzzle) {
                    gameOver();
                } else {
                    puzzleSet.current = settingsObj.puzzleSet;
                    reset();
                    setPuzzle(matchingPuzzle);
                }
            } else {
                gameOver();
            }
        } else {
            puzzleSet.current = settingsObj.puzzleSet;
        }

        vowelCost.current = settingsObj.vowelCost;
        turnTimerDuration.current = (settingsObj.useTurnTimer) ? settingsObj.turnTimerDuration : 0;
        itemToggles.current = {...settingsObj.itemToggles};

        if (settingsObj.playerCount > groups.length || settingsObj.restartRound) {
            let groupsUpdate = groups.slice();
            for (let i = groups.length; i < settingsObj.playerCount; i++) {
                groupsUpdate.push(
                    {id: i+1, name:`Group ${i+1}`, roundPoints: 0, gamePoints: 0, completedTurn: false, items: []},
                )
            }
            for (let i = 0; i < groupsUpdate.length; i++) {
                groupsUpdate[i].roundPoints = 0;
                groupsUpdate[i].completedTurn = false;
            }
            setGroups(groupsUpdate);
        }

        if (settingsObj.backdrop !== backdropRawName.current) {
            backdropSelected(settingsObj.backdrop);
        }

        setShowSettings(false);

    }

    function gameOver() {

        function finish() {
            setShowPodium(true);
            if (getWinnerData()) {
                window.UNMEIWA.sounds[podiumAud].play();
                window.UNMEIWA.sounds[cheerAud].play();
            } else {
                window.UNMEIWA.sounds[loseATurnAud].play();
            }

        }

        let groupsUpdate = groups.slice();
        let hasRoundPoints = groupsUpdate.find((x)=> x.roundPoints > 0);

        if (hasRoundPoints && !skipped.current) {
            groupsUpdate.forEach((x)=> {
                updateGroup(x.id, {
                    gamePoints: x.gamePoints + x.roundPoints,
                    roundPoints: 0,
                    completedTurn: false
                });
            });

            let aud = window.UNMEIWA.sounds[bankAud];
            aud.onended = finish;
            setGroups(groupsUpdate);
            aud.play().catch(finish);
        } else {
            finish();
        }
    }

    function endGame() {
        puzzleSet.current.forEach((x) => {x.finished = true;});
        setPuzzleSolved(true);
    }

    function podiumContinueHandler() {
        window.location.reload();
    }

    function getWinnerData() {
        let winnerData = [];
        let groupSlice = groups.slice();
        let hasPoints = groupSlice.find((x)=> x.gamePoints > 0);
        let scoreSet;

        if (hasPoints) {
            groupSlice.sort((a,b) => b.gamePoints - a.gamePoints);

            // This finds the top three gamePoints scores.
            // If there are teams with the same score, it will add them to the list until there are three unique scores in the set.
            groupSlice = groupSlice.reduce((x, val, idx)=> {
                scoreSet = new Set(x.map((y)=> y.gamePoints));
                let count = scoreSet.size;
                return (count < 3 || (x.length > 0 && x[x.length - 1].gamePoints === val.gamePoints)) ? [...x, val] : x;
            }, []);

            // We need to do this again since the final result from reduce will skip the 3rd score.
            scoreSet = new Set(groupSlice.map((y)=> y.gamePoints));

            let groupWithTBB = groupSlice.find(e => e.items?.some(f => f.name === ITEMS.TIE_BREAKER_BELT.name));

            for (let i = 0; i < groupSlice.length; i++) {
                let group = groupSlice[i];
                let newWinner = {
                    id: group.id,
                    name: group.name,
                    rank: [...scoreSet].indexOf(group.gamePoints) + 1,
                    score: group.gamePoints,
                    hasTBB: (groupWithTBB && groupWithTBB.id === group.id)
                };

                if ((groupWithTBB && group.gamePoints === groupWithTBB.gamePoints && !newWinner.hasTBB) ||
                    (!newWinner.hasTBB && newWinner.rank <= winnerData.length)) {
                    newWinner.rank = newWinner.rank + 1;
                }

                let winnerWithSameScore = winnerData.find(e => e.score === group.gamePoints && !e.hasTBB);

                if (winnerWithSameScore && !newWinner.hasTBB) {
                    let name = (winnerWithSameScore.name.indexOf("Tie (") !== -1) ? winnerWithSameScore.name.substring(5, winnerWithSameScore.name.length-1) : winnerWithSameScore.name;
                    winnerWithSameScore.name = `Tie (${name}, ${group.name})`;
                } else {
                    winnerData.push(newWinner);
                }
            }

            let winners = winnerData.sort((a, b) => a.rank - b.rank).map((winner, place) => ({ ...winner, place }));

            return winners.slice(0,3);
        } else {
            return null;
        }
    }

    function hasNewUpdates() {

        if (hasUpdates.current)
            return;

        let lastUpdateSeen = localStorage.getItem(LS_UPDATE_NOTICE_KEY);
        if (lastUpdateSeen == null || lastUpdateSeen < (UPDATE_NOTICES.length - 1)) {
            localStorage.setItem(LS_UPDATE_NOTICE_KEY, UPDATE_NOTICES.length - 1);
            hasUpdates.current = true;
        } else {
            console.log("Skipping update notice.");
        }
        setShowUpdates(hasUpdates.current);
    }



    function getCurrentSettings() {
        return {
            puzzleSet: puzzleSet.current,
            vowelCost: vowelCost.current,
            playerCount: groups.length,
            puzzleID: puzzle.id,
            activeBackdrop: backdropRawName.current,
            turnTimerDuration: turnTimerDuration.current,
            itemToggles: itemToggles.current
        }
    }

    let currentGroup = getGroupByID(playingGroupID.current);
    let dialogOpen = showRouletteWheel || showLetterSelector || showSolveThePuzzle || showPodium;
    let fullyLoaded = loaded && setUp;

    const skipButton = useDebounce(()=>{
        if (!puzzleSolved) {
            skipped.current = true;
            puzzleSet.current.find((x)=> x.id === puzzle.id).finished = true;
            setPuzzleSolved(true);
        }
    });

    function autoSettings() {
        if (DEBUG) {
            const debugSettings = {
                "puzzleSet": [
                    {
                        "id": "26007839-24c7-4146-9239-9749b12f8bc1",
                        "category": "ASDFASDF1",
                        "puzzle": "SFDFGSDFGSDFGS",
                        "finished": false
                    },
                    {
                        "id": "b91c189d-fe3a-4a6f-8d31-f17a98fff09c",
                        "category": "SDFGSDFGAW2",
                        "puzzle": "SDFGSDFG ASDFQE H FS DFASD G",
                        "finished": false
                    },
                    {
                        "id": "4e744e5d-0eac-4688-8867-affe1eeb548f",
                        "category": "SDFGGRRRRR3",
                        "puzzle": "H35Y35HDF FGSDFG 35Y DHDHGDF",
                        "finished": false
                    },
                    {
                        "id": "1dc7538f-4eee-4597-98de-26b517719d94",
                        "category": "ASDF Q4TADF4",
                        "puzzle": "ASDF Q34TFG TU W4HAFS W",
                        "finished": false
                    },
                    {
                        "id": "638ab2de-f016-48a9-94c4-f0d711155563",
                        "category": "WEFONAVLN5",
                        "puzzle": "PIFJG WE SDFPKMWF W VSDVWD",
                        "finished": false
                    },
                    {
                        "id": "d5190296-8326-4e7c-ac50-1857dcad06af",
                        "category": "FGSDF GQWEW6",
                        "puzzle": "SDF OKKPASDFM WEFASD        ",
                        "finished": false
                    }
                ],
                "playerCount": 4,
                "backdrop": "var(--umw-col-red)",
                "vowelCost": 500,
                "useTurnTimer": false,
                "itemToggles": itemToggles.current,
                "turnTimerDuration": 15,
                "restartRound": false,
                "currentPuzzleDeleted": false,
                "nextPuzzleID": null
            };

            finalizeSetup(debugSettings);
        }
    }

    return (
        <div id="app-container">
            {fullyLoaded && showRouletteWheel && <RouletteWheel backdrop={backdrop} group={currentGroup} boardState={boardState} puzzle={puzzle} callback={afterRoulette} items={ITEMS} itemLimits={itemLimits.current} itemToggles={itemToggles.current}/>}
            {fullyLoaded && <LetterSelector timerDuration={turnTimerDuration.current} isFreeVowel={freeVowel.current} backdrop={backdrop} show={showLetterSelector} vowelsEnabled={buyingAVowel.current} boardState={boardState} puzzle={puzzle} callback={afterLetterSelection}/>}
            {fullyLoaded && showSolveThePuzzle && <SolveThePuzzle backdrop={backdrop} boardState={boardState} puzzle={puzzle} timerDuration={turnTimerDuration.current} correctCallback={correctSolve} incorrectCallback={incorrectSolve}/>}
            {fullyLoaded && !dialogOpen && !showSettings && (<>
                <PuzzleBoard showNextBtn={(puzzleSolved && hasNextPuzzle() && !itemNotificationResolver)} showFinishBtn={!hasNextPuzzle()} backdrop={backdrop} nextCallback={finishPuzzle} solved={puzzleSolved} puzzle={puzzle} boardState={boardState} guessedCallback={handleGuessedClick}/>
                {!showTurnOptions &&
                    <div id="lower-section" className="flex flex-nowrap">
                        <div id="game-buttons" className="flex flex-col gap-1 justify-between">
                            <button className="game-btn" onClick={setShowSettings.bind(this, true)}><SettingsImg height="100%"/></button>
                            <button className="game-btn" onClick={skipButton}><SkipImg height="100%"/></button>
                            <button className="game-btn" onClick={endGame}><StopImg height="100%"/></button>
                            <button className="game-btn" onClick={setShowHelp.bind(this, true)}><HelpImg height="100%"/></button>
                        </div>
                        <GroupDisplay updateGroupCallback={updateGroup} currentGroup={playingGroupID.current} groups={groups} clickHandler={handleGroupClick}/>
                    </div>}
                {showTurnOptions && <TurnOptionSelector timerDuration={turnTimerDuration.current} consonantsUsed={consonantsUsed.current} vowelsPurchased={vowelsPurchased.current} vowelCost={vowelCost.current} group={currentGroup} callback={doTurnOption}/>}
            </>)}
            {!loaded && <Preloader callback={()=> {
                audioContainer.current = new Audio();
                audioContainer.autoplay = true;
                audioContainer.src = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
                setLoaded(true);
            }}
                                   images={[playImg, bankImg, walletImg, noWinnerImg, ...Object.values(backdrops)]}
                                   audio={AUDIO} />}
            {loaded && !setUp && <GameSetup setupCallback={finalizeSetup} backdropCallback={backdropSelected}/>}
            {fullyLoaded && showSettings && <GameSetup isInGame={true} currentSettings={getCurrentSettings()} updateCallback={updateSettings}/>}
            {fullyLoaded && showItem && <ItemPopup item={showItem} acquiring={false} onClose={closeItemNotification}/>}
            {fullyLoaded && showItemAcquisitionEffect && <ItemEffectPointsPopup item={showItemAcquisitionEffect} group={currentGroup} onClose={closeItemNotification}/>}
            {fullyLoaded && showPodium && <WinnerPodium continueCallback={podiumContinueHandler} winners={getWinnerData()}/>}
            {fullyLoaded && showHelp && <HelpScreen closeCallback={setShowHelp.bind(this, false)}/>}
            {fullyLoaded && showGroupChooser && <ChooseGroupPopup item={showGroupChooser} groups={groups} onClose={closeGroupChooser} onChoice={closeGroupChooser}/>}
            {!loaded && showUpdates && <UpdateNotice closeCallback={setShowUpdates.bind(this, false)} updates={UPDATE_NOTICES}/>}
        </div>
    );

    async function leechPoints(item, ownerGroup, allGroups) {

        item.resolved = true;

        let groupSlice = allGroups.slice();
        let hasPoints = groupSlice.find((x)=> x.roundPoints > 0);

        if (hasPoints) {
            groupSlice.sort((a, b) => b.roundPoints - a.roundPoints);

            let topGroup = groupSlice[0];

            if (topGroup.id !== ownerGroup.id) {

                window.UNMEIWA.sounds[pointLeechAud].play();
                let pointsStolen = Math.round(topGroup.roundPoints * 0.25);
                topGroup.roundPoints = topGroup.roundPoints - pointsStolen;
                ownerGroup.roundPoints = ownerGroup.roundPoints + pointsStolen;

                setGroups([...allGroups]);
                await showItemNotificationAndWait(item);

            }
        }

    }

    async function isPoisoned(item, group, allGroups) {

        item.resolved = true;

        if (item.name === ITEMS.POISON_STATUS.name && playingGroupID.current === group.id) {
            window.UNMEIWA.sounds[poisonedAud].play();
            group.roundPoints -= item.points;
            item.uses += 1;
            item.description = (typeof item.description === "function") ? item.description(group.name) : item.description;
            setGroups([...allGroups]);
            await showItemNotificationAndWait(item);
        }

    }

    async function ringOfPower(item, group, allGroups, props) {

        // How to know how many points added in this turn?
        // Possible sources:
        //      Guessing a letter
        //      Solving the puzzle
        item.resolved = true;
        if (item.name === ITEMS.RING_OF_POWER.name && playingGroupID.current === group.id) {
            window.UNMEIWA.sounds[ringOfPowerAud].play();
            group.roundPoints += Math.round(props.pointsDelta * 0.25);
            setGroups([...allGroups]);
            if (!item.hasOwnProperty("notificationLimit") || item.notifications < item.notificationLimit) {
                item.notifications += 1;
                await showItemNotificationAndWait(item);
            }
        }
    }

    async function usePoison(item, group, groups) {
        window.UNMEIWA.sounds[poisonedAud].play();
        let groupID = await showGroupChooserAndWait(item);
        if (groupID && groupID !== group.id) {
            alert(`Choosing to poison group ${groupID}!`);
            let poisonedGroup = groups.find((x) => x.id === groupID);
            if (poisonedGroup && poisonedGroup.items) {
                group.items.splice(group.items.findIndex((x) => x === item), 1);
                let itemIdx = poisonedGroup.items.length;
                if (poisonedGroup.items.length === 3) {
                    itemIdx = Math.floor(Math.random() * 3);
                }
                poisonedGroup.items[itemIdx] = {...ITEMS.POISON_STATUS};
                setGroups([...groups]);
            }
        } else if (groupID && groupID === group.id) {
            alert("You can't poison your own group!");
        }
    }

    async function bomb(item, group) {
        item.resolved = true;
        if (item.name === ITEMS.BOMB.name && playingGroupID.current === group.id && group.roundPoints > 0) {

            let groupsUpdate = groups.slice();
            setTimeout(() => {
                window.UNMEIWA.sounds[bombAud].play();
                group.roundPoints = 0;
                setGroups([...groupsUpdate]);
            }, 500);
            await showItemNotificationAndWait(item);
        }
    }

    async function treasure(item, group) {
        item.resolved = true;
        if (item.name === ITEMS.TREASURE.name && playingGroupID.current === group.id) {

            let groupsUpdate = groups.slice();
            setTimeout(() => {
                window.UNMEIWA.sounds[treasureAud].play();
                group.roundPoints += 2000;
                setGroups([...groupsUpdate]);
            }, 500);
            await showItemNotificationAndWait(item);
        }
    }
}



export default App;
