import React from 'react';

import { CountdownCircleTimer } from 'react-countdown-circle-timer'

function Timer(props) {

    const onExpire = props?.onExpire || (() => {});
    const duration = props?.duration || 10;
    const textColor = props?.textColor || 'var(--umw-col-font-dark)';
    const size = props?.size || 90;
    const pause = props?.pause === true;

    const fontSize = (size === 90) ? '2rem' : `${(size * 0.356)}px`;

    const renderTime = ({ remainingTime }) => {

        if (pause) {
            return;
        }

        if (remainingTime === 0) {
            return (<div className="time-wrapper">
                <div className="time" style={{color: textColor, fontSize: fontSize}}>✕</div>
            </div>);
        }

        return (
            <div className="time-wrapper">
                <div className="time" style={{color: textColor, fontSize: fontSize}}>{remainingTime}</div>
            </div>
        );
    };
    
    return (
        <div className="timer-wrapper">
            <CountdownCircleTimer
                isPlaying={!pause}
                size={size}
                duration={duration}
                colors={["#0c7700", "#F7B801", "#fc8800", "#A30000"]}
                colorsTime={[duration, duration*0.66, duration*0.33, 0]}
                onComplete={() => {
                    setTimeout(onExpire,500);
                }}
            >
                {renderTime}
            </CountdownCircleTimer>
        </div>
    );

}

export default Timer;