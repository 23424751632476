import {ReactComponent as TieBreakerBeltImg} from "./static/img/items/tiebreakerbelt.svg";
import {ReactComponent as PointLeechImg} from "./static/img/items/pointleech.svg";
import {ReactComponent as PoisonImg} from "./static/img/items/poison.svg";
import {ReactComponent as RingOfPowerImg} from "./static/img/items/ringofpower.svg";
import {ReactComponent as PoisonedImg} from "./static/img/poison_status.svg";
import {ReactComponent as BombImg} from "./static/img/items/bomb.svg";
import {ReactComponent as TreasureImg} from "./static/img/items/treasure.svg";

export const ITEM_HOOKS = {
    START_OF_ROUND: 2,
    START_OF_TURN: 3,
    END_OF_TURN: 4,
    END_OF_ROUND: 5,
    END_OF_GAME: 6,
    GAIN_POINTS: 7,
    LOSE_POINTS: 8,
    USE_ITEM: 9,
    TARGETED_BY_ITEM: 10,
    ACQUISITION: 11
}

export const ITEMS = {
    TIE_BREAKER_BELT:
    {
        name: "Tie-breaker Belt",
        weight: 1,
        description: "Any team with this belt will always come out on top if there is a tie!",
        round_expire: false,
        img: TieBreakerBeltImg,
        resolved: false
    },
    POINT_LEECH:
    {
        name: "Point Leech",
        weight: 2,
        description: "Any team with this item will receive 25% of the top-scoring team's points at the end of the round!",
        round_expire: true,
        hook: ITEM_HOOKS.END_OF_ROUND,
        activate: null, // leechPoints
        img: PointLeechImg,
        resolved: false
    },
    POISON:
    {
        name: "Poison",
        weight: 2,
        description: "Choose a team. That team will lose 500 round points each turn for three turns! Being poisoned takes up an item slot. If the poisoned team's item slots are full, one item will randomly be removed.",
        round_expire: true,
        img: PoisonImg,
        clickHandler: null, // usePoison
        resolved: false
    },
    POISON_STATUS:
    {
        name: "Poisoned",
        weight: 0,
        description: (name) => `${name} is poisoned! They will lose 500 points for three turns.`,
        round_expire: true,
        hook: ITEM_HOOKS.END_OF_TURN,
        activate: null, // isPoisoned
        uses: 0,
        useLimit:3,
        points: 500,
        target:"self",
        img: PoisonedImg,
        resolved: false,
        unattainable: true
    },
    RING_OF_POWER:
    {
        name: "Ring of Power",
        weight: 1,
        description: "Any team with this ring will receive a 25% boost to the points they receive during the round!",
        round_expire: true,
        hook: ITEM_HOOKS.GAIN_POINTS,
        activate: null, // ringOfPower
        uses: 0,
        useLimit: -1,
        notifications: 0,
        notificationLimit: 1,
        target: "self",
        img: RingOfPowerImg,
        resolved:false
    },
    BOMB: {
        name: "Bomb",
        weight: 1,
        description: "Oh no! You found a bomb! Your team's points are now 0.",
        hook: ITEM_HOOKS.ACQUISITION,
        activate: null, // bomb
        img: BombImg
    },
    TREASURE: {
        name: "Treasure",
        weight: 1,
        description: "You found a treasure chest! Your team gains 2000 points!",
        hook: ITEM_HOOKS.ACQUISITION,
        activate: null, // treasure
        img: TreasureImg
    }

};

export const ITEM_LIMITS_BASE = {
    TIE_BREAKER_BELT: {
        game_limit: 1,
        game_uses: 0
    },
    POINT_LEECH: {
        round_limit: 1,
        round_uses: 0
    },
    POISON: {
        round_limit: 2,
        round_uses: 0
    },
    RING_OF_POWER: {
        round_limit: 1,
        round_uses: 0
    },
    BOMB: {
        round_limit: 1,
        round_uses: 0
    },
    TREASURE: {
        round_limit: 1,
        round_uses: 0
    }
};

export const ITEM_TOGGLES_BASE = {
    BOMB: true,
    TREASURE: true,
    TIE_BREAKER_BELT: true,
    POINT_LEECH: true,
    POISON: true,
    RING_OF_POWER: true
};

export const getItemKeyByName = function (itemName) {

    return Object.entries(ITEMS).reduce((acc, [key, value]) => {
        if (value.name === itemName) {
            acc.push(key);
        }
        return acc;
    }, [])[0];



};
