import React, {} from 'react';

function ChooseGroupPopup(props) {
    const item = props?.item;
    const groups = props?.groups || [];
    const onChoice = props?.onChoice || (() => {});
    const onClose = props?.onClose || (() => {});

    function choiceMade(groupID) {
        if (onChoice) {
            onChoice(groupID);
        }
    }

    function getGroups() {

        let groupElements = [];

        if (groups) {
            for (const group of groups) {
                groupElements.push(<div
                    className="group-btn"
                    data-group={group.id}
                    key={`gb-${group.id}`}
                    onClick={choiceMade.bind(null, group.id)}>
                    <span>{group.name}</span>
                </div>);
            }
        }

        return groupElements;

    }


    return (
        <div id="choose-group-popup" className="flex gap-1 centered-flex">
            <div className="flex flex-col flex-1 gap-1 centered-flex">
                <span className="item-title">{item?.name}</span>
                {function() {
                    let Comp = item?.img;
                    return (Comp) ? <i className="item-icon"><Comp height="100%"/></i> : <i></i>;
                }()}
                <p className="item-description">{item?.description}</p>
                <button id="backBtn" onClick={onClose}>Back</button>
            </div>
            <div id='group-btn-holder' className="flex gap-1 flex-1 h-100 justify-around align-items-center">
                {getGroups()}
            </div>
        </div>
    );
}

export default ChooseGroupPopup;