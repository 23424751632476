import React, {useEffect, useState} from 'react';

import {ReactComponent as BankImg} from '../../static/img/bank.svg'
import {ReactComponent as WalletImg} from '../../static/img/wallet.svg';
import {ReactComponent as SaveImg} from "../../static/img/save.svg";
import PoisonStatusImg from "../../static/img/poison_status.png";
import AnimatedNumber from "../AnimatedNumber";

function GroupDisplay(props) {

    const groups = props?.groups;
    const currentGroup = props?.currentGroup;
    const groupClickHandler = props?.clickHandler || (()=>{});
    const updateGroupCallback = props?.updateGroupCallback;
    const [showEditGroup, setShowEditGroup] = useState(false);
    const [editingGroup, setEditingGroup] = useState(null);

    useEffect(() => {
        if (currentGroup) {
            let el = document.querySelector(`[data-group="${currentGroup}"]`);
            if (el) {
                el.scrollIntoView({behavior: "instant", inline: "center"});
            }
        } else if (groups) {
            for (const group of groups) {
                if (!group.completedTurn) {
                    let el = document.querySelector(`[data-group="${group.id}"]`);
                    if (el) {
                        el.scrollIntoView({behavior: "instant", inline: "center"});
                    }
                    break;
                }
            }
        }

        let el = document.querySelector('#group-display');
        el.classList.toggle("scrollbarOn", el.scrollWidth > el.clientWidth);

    }, [currentGroup]);

    useEffect(() => {

        let el = document.querySelector('#group-display');
        el.addEventListener("wheel", (evt) => {
            evt.preventDefault();
            if (evt.deltaY >= -15 && evt.deltaY <= 15) {
                el.scrollLeft += (evt.deltaY * 40);
            } else {
                el.scrollLeft += (evt.deltaY * 5);
            }
        });

    }, []);

    function openGroupOptions(group, e) {
        e.stopPropagation();
        setEditingGroup(group);
        setShowEditGroup(true);
    }

    function updateGroup(prop, e) {
        let groupUpdate = {...editingGroup};
        if (prop && groupUpdate.hasOwnProperty(prop)) {
            let val = e.target.value;
            switch (prop) {
                case "name":
                    groupUpdate[prop] = val;
                    break;
                case "roundPoints":
                case "gamePoints":
                    val = Number(val);
                    let isValid = !isNaN(val);
                    if (isValid && val >= 0 && val <= 99999) {
                        groupUpdate[prop] = val;
                    }
                    break;
                default:
                    return;
            }
            setEditingGroup(groupUpdate);
        }
    }

    function finishEdit() {
        setShowEditGroup(false);
        updateGroupCallback(editingGroup.id, editingGroup);
        setEditingGroup(null);
    }

    function itemClick(item, group) {
        return function(e) {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            if (item?.clickHandler) {
                item.clickHandler(item, group, groups);
            }
        };
    }

    function getGroups() {
        let groupElements = [];

        if (groups) {
            for (const group of groups) {
                let isEditing = (showEditGroup && editingGroup?.id === group.id);
                groupElements.push(<div
                    className={"group" +
                        ((group.completedTurn) ? " finished" : "") +
                        ((isEditing) ? " editing" : "")}
                    data-group={group.id}
                    key={`g-${group.id}`}
                    onClick={(showEditGroup) ? (()=>{}) : groupClickHandler.bind(this, group.id)}>
                    <div className="flex flex-nowrap align-items-center gap-1">
                        {!isEditing && <span className="group-name flex-1">{group.name}</span>}
                        {(!isEditing && group?.isPoisoned) && <span className="group-status">{PoisonStatusImg}</span>}
                        {isEditing && <input className="group-name flex-1" type="text" value={editingGroup.name} onChange={updateGroup.bind(this, "name")}/>}
                        <button className="group-options"
                                onClick={(isEditing) ? finishEdit : openGroupOptions.bind(this, group)}>{(isEditing) ? <SaveImg height="100%"/> : "⋮"}</button>

                    </div>
                    <div id="group-info" className="flex flex-col gap-1">
                        <div className="points-display flex-1">
                            <span className="group-round-points"><i><WalletImg height="100%"/></i>
                                {!isEditing && <AnimatedNumber value={group.roundPoints}/>}
                                {isEditing && <input type="text"
                                                     onChange={updateGroup.bind(this, "roundPoints")}
                                                     value={editingGroup.roundPoints}/>}
                            </span>
                                <span className="group-game-points"><i><BankImg height="100%"/></i>
                                    {!isEditing && <AnimatedNumber value={group.gamePoints}/>}
                                    {isEditing && <input type="text"
                                                         onChange={updateGroup.bind(this, "gamePoints")}
                                                         value={editingGroup.gamePoints}/>}
                            </span>
                        </div>
                        {!isEditing &&
                        <div id="group-items" className="flex flex-1 flex-nowrap align-items-center gap-1 flex-1 h-100">
                            <div className="item-row flex flex-nowrap align-items-center gap-1 w-100 h-100 flex-1">
                                <div className="item-box flex centered-flex flex-1 h-100" onClick={itemClick(group.items[0], group)}>
                                    <div className="item flex">
                                        {function() {
                                            let Comp = (group?.items && group.items.length > 0) ? group.items[0].img : null;
                                            return (Comp) ? <i><Comp height="100%"/></i> : <i></i>;
                                        }()}
                                    </div>
                                </div>
                                <div className="item-box flex centered-flex flex-1 h-100" onClick={itemClick(group.items[1], group)}>
                                    <div className="item flex">
                                        {function() {
                                            let Comp = (group?.items && group.items.length > 1) ? group.items[1].img : null;
                                            return (Comp) ? <i><Comp height="100%"/></i> : <i></i>;
                                        }()}
                                    </div>
                                </div>
                                <div className="item-box flex centered-flex flex-1 h-100" onClick={itemClick(group.items[2], group)}>
                                    <div className="item flex">
                                        {function() {
                                            let Comp = (group?.items && group.items.length > 2) ? group.items[2].img : null;
                                            return (Comp) ? <i><Comp height="100%"/></i> : <i></i>;
                                        }()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>

                </div>);
            }
        }

        return groupElements;
    }

    return (
        <div id="group-display">
            {getGroups()}
        </div>
    );
}

export default GroupDisplay;