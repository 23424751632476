import React, {useState} from 'react';
import PuzzleBoard from "../PuzzleBoard";

import solvedAud from '../../static/audio/solved.mp3';
import useDebounce from "../../useDebounce";
import Timer from "../Timer";

function SolveThePuzzle(props) {

    const boardState = props?.boardState;
    const correctCallback = useDebounce(props?.correctCallback);
    const incorrectCallback = useDebounce(props?.incorrectCallback);
    const puzzle = props?.puzzle;
    const backdrop = props?.backdrop;
    const timerDuration = props?.timerDuration || 0;
    const [showContinue, setShowContinue] = useState(false);


    function _setSolved() {
        window.UNMEIWA.sounds[solvedAud].play();
        setTimeout(() => {
            setShowContinue(true);
        }, 300);
    }

    const setSolved = useDebounce(_setSolved);

    let alreadySolved = false;
    if (boardState) {
        let emptySquare = boardState.find((x)=> x.used && !x.show);
        if (!emptySquare) {
            alreadySolved = true;
        }
    }

    return (
        <div id="solve-the-puzzle">
            <PuzzleBoard backdrop={backdrop} solved={showContinue} puzzle={puzzle} boardState={boardState}/>
            {(!showContinue && !alreadySolved) &&
                <div id="response-holder">
                    <button id="correctSolveBtn" onClick={setSolved}>Correct</button>
                    {(timerDuration !== 0) && <Timer duration={timerDuration} onExpire={incorrectCallback} textColor={"var(--umw-col-font-light)"}/>}
                    <button id="incorrectSolveBtn" onClick={incorrectCallback}>Incorrect</button>
                </div>
            }
            {(showContinue || alreadySolved) && <div id="response-holder">
                <button id="continueBtn" onClick={correctCallback}>Continue</button>
            </div>}
            {(showContinue || alreadySolved) && <div className="pyro">
                <div className="before"></div>
                <div className="after"></div>
            </div>}
        </div>
    );
}

export default SolveThePuzzle;