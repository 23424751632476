import React, {useState} from 'react';
import {ReactComponent as WalletImg} from "../../static/img/wallet.svg";
import AnimatedNumber from "../AnimatedNumber";
import {ReactComponent as BankImg} from "../../static/img/bank.svg";
import Timer from "../Timer";
import incorrectAud from "../../static/audio/incorrect.mp3";

function TurnOptionSelector(props) {

    const selectionCallback = props?.callback || (()=>{});
    const group = props?.group;
    const vowelCost = props?.vowelCost || 500;
    const vowelsPurchased = props?.vowelsPurchased;
    const consonantsUsed = props?.consonantsUsed;
    const timerDuration = props?.timerDuration || 0;
    const [selectionMade, setSelectionMade] = useState(false);

    let gClass;

    if (group?.id) {
        let mod = (group.id % 4 === 0) ? 4 : group.id % 4;
        gClass = `g${mod}`;
    }

    let allowBuyVowel = (group?.roundPoints >= vowelCost && vowelsPurchased.length < 5);
    let allowSpin = (consonantsUsed.length < 21);

    function processSelection(choice) {
        setSelectionMade(true);
        if (selectionCallback) {
            selectionCallback(choice);
        }
    }


    return (
        <div id="turn-option-selector" className={gClass}>
            <div id="game-buttons" className="flex flex-col gap-1 justify-between">
                <button id="cancelBtn" className="game-btn" onClick={processSelection.bind(null, -1)}>‹</button>
            </div>
            <div id="group-hud-container" className="flex gap-1 justify-between">
                {group &&  <div id="currentGroupHUD">
                    <div className={"group " + gClass}>
                        <span className="group-name">{group.name}</span>
                        <div className="points-display">
                            <span className="group-round-points"><i><WalletImg height="100%"/></i>
                            <AnimatedNumber value={group.roundPoints}/></span>
                            <span className="group-game-points"><i><BankImg height="100%"/></i>
                            <AnimatedNumber value={group.gamePoints}/></span>
                        </div>
                    </div>
                </div>
                }
                <div id="button-holder">
                    <div>
                        <button id="optionSpin" className={(!allowSpin) ? "disabled" : ""} onClick={processSelection.bind(null, 0)}>Spin</button>
                        <button id="optionSolve" onClick={processSelection.bind(null, 2)}>Solve</button>
                    </div>
                    {(timerDuration !== 0) && <Timer pause={selectionMade} duration={timerDuration} onExpire={() => {
                        let aud = window.UNMEIWA.sounds[incorrectAud];
                        setSelectionMade(true);
                        let finish = processSelection.bind(null, 3);
                        aud.onended = finish;
                        aud.play().catch(finish);
                    }}/>}
                    <div>
                        <button id="optionVowel" className={(!allowBuyVowel) ? "disabled" : ""} onClick={processSelection.bind(null, 1)}>Buy vowel</button>
                        <button id="optionPass" onClick={processSelection.bind(null, 3)}>Pass</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TurnOptionSelector;